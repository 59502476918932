/* width */
::-webkit-scrollbar {
	width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ffffff00;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ffffff00;
}

.active {
	background-color: #0c65d9c4 !important;
	color: #fff !important;
	.MuiSvgIcon-root {
		color: #fff !important;
	}
	.MuiListItemText-root {
		color: #fff !important;
	}
}
